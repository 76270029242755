// IMPORTANT: FILE MUST BE INCLUDED AT THE END OF THE HTML
//
// Even though we use jquery ready; `.countdown` gets added only
// if the script is put at then end.
//
// Gotta love JS libraries.

require("jquery");
require("jquery-countdown");
const moment_ = require("moment-timezone");

// Used for event detail page
// For what's on, a similar script is called via React

$(document).ready(function () {
  $("[data-countdown]").each(function () {
    let $this = $(this);

    let finalDate = moment_
      .tz($this.data("countdown"), "Australia/Sydney")
      .toDate();

    $this.countdown(finalDate, function (event: any) {
      $this.html(event.strftime("%H:%M:%S"));
    });
  });
});
